import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Icon from "../components/Icon"

import towing1 from "../images/towing/towing1.jpg"
import towing2 from "../images/towing/towing2.jpg"
import towing3 from "../images/towing/towing3.jpg"

const Towing = ({ location }) => (
  <Layout location={location} >
    <SEO title="Towing" />
    <div className="row">
      <div className="col-md-8 content-left">
        <h1>24/7 Towing Services</h1>
        <p>We use modern tilt tray services to transport all vehicles.</p>
        <p>We also ensure that our staff are kept up-to-date with training and the latest technology in the industry.</p>
        <div className="col-12 content-nav">
          <ul className="nav flex-column">
            <li><Icon name="chevron-right" />24 Hr Emergency and Breakdown service</li>
            <li><Icon name="chevron-right" />Tilt Tray Service</li>
          </ul>
        </div>
      </div>
      <div className="col-md-4 content-right">
        <h1>Need a tow?</h1>
        <a href="tel:0412528293"><Icon name="phone" />0412 528 293</a>
      </div>
    </div>
    <div className="row">
      <div className="col-md-4">
        <img className="content-image" src={towing1} alt="Tow Truck 1" />
      </div>
      <div className="col-md-4">
        <img className="content-image" src={towing2} alt="Tow Truck 2" />
      </div>
      <div className="col-md-4">
        <img className="content-image" src={towing3} alt="Tow Truck 3" />
      </div>
    </div>
  </Layout>
)

export default Towing